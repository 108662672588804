import React from 'react';
import { Container } from 'reactstrap';
import SidebarLayout from '../components/SidebarLayout';
import D3Visual from '../components/D3Visual.js';

export default class D3Test extends React.Component {
  render() {
    return (
      <SidebarLayout>
        <Container fluid>
          <h1> The D3 Visualization </h1>

          <p> Drag the visualization to see the newsletters on the different
          axes. Hover over a point to see the newsletter it corresponds to. </p>

          <D3Visual />
        </Container>

      </SidebarLayout>
    )
  }
}
